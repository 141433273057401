import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import requirementsReducer from './requirementsSlice';
import matterReducer from './matterSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['requirements', 'matter'],
};

const persistedRequirementsReducer = persistReducer(persistConfig, requirementsReducer);
const persistedMatterReducer = persistReducer(persistConfig, matterReducer);

export const store = configureStore({
  reducer: {
    requirements: persistedRequirementsReducer,
    matter: persistedMatterReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export const persistor = persistStore(store);
