import React, { useState, useEffect, useTransition } from 'react';
import { fetchCourts } from '../services/apiService';
import LoadingSpinner from './LoadingSpinner'; // Make sure you have this component

function CourtSelection({ stateId, onSelectCourt, disabled }) {
  const [courts, setCourts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedCourt, setSelectedCourt] = useState('');
  const [isPending, startTransition] = useTransition();

  useEffect(() => {
    if (stateId) {
      const getCourts = async () => {
        setLoading(true);
        try {
          const data = await fetchCourts(stateId);
          setCourts(data);
          setError(null);
        } catch (err) {
          setError('Failed to fetch courts');
        } finally {
          setLoading(false);
        }
      };
      getCourts();
    }
  }, [stateId]);

  const handleChange = (e) => {
    const { value } = e.target;
    startTransition(() => {
      setSelectedCourt(value);
      onSelectCourt(value);
    });
  };

  return (
    <div>
      <label htmlFor="court-select" className="court-select-label">
        Select Court:
      </label>
      {loading && <LoadingSpinner />}
      {error && <p>{error}</p>}
      <select
        id="court-select"
        className="select-input court-select"
        onChange={handleChange}
        value={selectedCourt}
        disabled={disabled || isPending}
      >
        <option value="">Select a court</option>
        {courts.map((court) => (
          <option key={court.id} value={court.id}>
            {court.name}
          </option>
        ))}
      </select>
      {isPending && <LoadingSpinner />}
      {' '}
      {/* Add this line */}
    </div>
  );
}

export default CourtSelection;
