/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useTransition } from 'react';
import { fetchMatters } from '../services/apiService';
import LoadingSpinner from './LoadingSpinner'; // Make sure you have this component

function MatterSelection({ courtId, onSelectMatter, disabled }) {
  const [matters, setMatters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isPending, startTransition] = useTransition();

  useEffect(() => {
    if (courtId) {
      setLoading(true);
      fetchMatters(courtId)
        .then((data) => {
          setMatters(data);
          setError(null);
        })
        .catch((err) => {
          setError('Failed to fetch matters');
          // eslint-disable-next-line no-console
          console.error('Error fetching matters:', err);
        })
        .finally(() => setLoading(false));
    }
  }, [courtId]);

  if (loading) return <LoadingSpinner />;
  if (error) return <p>{error}</p>;

  return (
    <div>
      <label htmlFor="matter-select" className="matter-select-label">
        Select Matter:
      </label>
      <select
        id="matter-select"
        className="select-input matter-select"
        onChange={(e) => startTransition(() => onSelectMatter(e.target.value))}
        disabled={disabled || isPending}
        defaultValue=""
      >
        <option value="" disabled>Select a matter</option>
        {matters.map((matter) => (
          <option key={matter.id} value={matter.id}>
            {matter.title}
          </option>
        ))}
      </select>
      {isPending && <LoadingSpinner />}
      {' '}
      {/* Add this line */}
    </div>
  );
}

export default MatterSelection;
