import React, { useState } from 'react';
// Remove the unused import
// import { format } from 'date-fns';

function DateInput({ onDateChange, disabled }) {
  const [selectedDate, setSelectedDate] = useState('');

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setSelectedDate(newDate);
    onDateChange(newDate);
  };

  return (
    <div>
      <label htmlFor="date-of-order-input" className="date-of-order-input-label">
        Enter Date of Order:
      </label>
      <input
        id="date-input"
        className="select-input date-input"
        type="date"
        value={selectedDate}
        onChange={handleDateChange}
        disabled={disabled}
      />
      {/* Remove the formatDate section */}
    </div>
  );
}

export default DateInput;
