import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { verifyMagicLink } from '../services/apiService';

function AuthVerify() {
  const [message, setMessage] = useState('Verifying...');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const verifyToken = async () => {
      const params = new URLSearchParams(location.search);
      const token = params.get('token');

      if (!token) {
        setMessage('Invalid token');
        return;
      }

      try {
        const response = await verifyMagicLink(token);
        if (response.success) {
          localStorage.setItem('userEmail', response.email);
          navigate('/');
        } else {
          setMessage('Invalid or expired token');
        }
      } catch (error) {
        setMessage('Error verifying token');
      }
    };

    verifyToken();
  }, [location, navigate]);

  return (
    <div className="auth-verify">
      <h2>{message}</h2>
    </div>
  );
}

export default AuthVerify;
