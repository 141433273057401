import axios from 'axios';

const api = axios.create({
  baseURL: process.env.NODE_ENV === 'production'
    ? 'https://auslawcalc.com.au/api'
    : 'http://localhost:3000/api'
});

export const fetchStates = async () => {
  try {
    const response = await api.get('/states');
    return response.data;
  } catch (error) {
    console.error('Error fetching states:', error.response?.data || error.message);
    throw error;
  }
};

export const fetchCourts = async (stateId) => {
  try {
    const response = await api.get(`/courts?stateId=${stateId}`);
    return response.data;
  } catch (error) {
    console.warn('Error fetching courts:', error);
    throw error;
  }
};

export const fetchMatters = async (courtId) => {
  try {
    const response = await api.get(`/matters?courtId=${courtId}`);
    return response.data;
  } catch (error) {
    console.warn('Error fetching matters:', error);
    throw error;
  }
};

export const fetchRequirementsByMatterId = async (matterId) => {
  try {
    const response = await api.get(`/requirements?matterId=${matterId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching requirements:', error);
    throw error;
  }
};

export const fetchCalculatedDates = async (matterId, dateOfOrder) => {
  try {
    console.log('Fetching calculated dates for:', { matterId, dateOfOrder });
    const [calculatedDates, requirements] = await Promise.all([
      api.post('/calculate-dates', { matterId, dateOfOrder }),
      fetchRequirementsByMatterId(matterId)
    ]);
    console.log('Received data:', { calculatedDates: calculatedDates.data, requirements });
    return {
      calculatedDates: calculatedDates.data.calculatedDates,
      requirements
    };
  } catch (error) {
    console.error('Error fetching calculated dates:', error);
    throw error;
  }
};

export const fetchDownloadableLink = async (stateId, courtId, isCriminalDivision) => {
  try {
    // eslint-disable-next-line max-len
    const response = await api.get(`/downloadable-link?stateId=${stateId}&courtId=${courtId}&isCriminalDivision=${isCriminalDivision}`);
    return response.data;
  } catch (error) {
    console.warn('Error fetching downloadable link:', error);
    throw error;
  }
};

export const sendMagicLink = async (email) => {
  console.log('Sending magic link for:', email);
  try {
    const response = await api.post('/auth/magic-link', { email });
    console.log('Magic link sent successfully');
    return response.data;
  } catch (error) {
    console.error('Error sending magic link:', error);
    throw error;
  }
};

export const verifyMagicLink = async (token) => {
  try {
    const response = await api.get(`/auth/verify?token=${token}`);
    return response.data;
  } catch (error) {
    console.error('Error verifying magic link:', error);
    throw error;
  }
};

export const saveCalculation = async (calculationData) => {
  try {
    console.log('Sending calculation data to server:', calculationData);
    const response = await api.post('/saved-calculations/save', calculationData);
    console.log('Server response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error saving calculation:', error);
    throw error;
  }
};

export const loadCalculation = async (uniqueId) => {
  try {
    const response = await api.get(`/saved-calculations/${uniqueId}`);
    console.log('Loaded calculation data:', JSON.stringify(response.data, null, 2));

    const loadedData = response.data;

    // Reconstruct the requirements array with the saved dates
    const requirements = Object.entries(loadedData.requirements).map(([description, data]) => {
      const requirement = {
        description,
        ...data,
        calculatedDate: loadedData.calculatedDates[description],
        chosenDate: data.chosenDate || loadedData.calculatedDates[description]
      };
      console.log(`Reconstructed requirement: ${JSON.stringify(requirement, null, 2)}`);
      return requirement;
    });

    const result = {
      ...loadedData,
      requirements
    };

    console.log('Final loaded data:', JSON.stringify(result, null, 2));
    return result;
  } catch (error) {
    console.error('Error loading calculation:', error);
    throw error;
  }
};

export const calculateCriminalDates = async (criminalDates, proceedingTypes) => {
  try {
    const proceedingTypesArray = Array.isArray(proceedingTypes) ? proceedingTypes : [proceedingTypes].filter(Boolean);
    console.log('Sending criminal dates to server:', { ...criminalDates, proceedingTypes: proceedingTypesArray });
    // eslint-disable-next-line max-len
    const response = await api.post('/calculate-criminal-dates', { ...criminalDates, proceedingTypes: proceedingTypesArray });
    console.log('Received response from server:', response.data);
    return {
      calculatedDates: response.data.calculatedDates,
      requirements: response.data.requirements
    };
  } catch (error) {
    console.error('Error calculating criminal dates:', error);
    throw error;
  }
};
