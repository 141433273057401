import axios from 'axios';

const API_URL = process.env.NODE_ENV === 'production'
  ? 'https://auslawcalc.com.au/api'
  : 'http://localhost:3000/api';

export const addEventToCalendar = async (title, date, calendarType, token) => {
  console.log('Adding event to calendar:', { title, date, calendarType });
  try {
    const response = await axios.post(`${API_URL}/calendar/add`, {
      title,
      date,
      calendarType,
      token,
    });
    console.log('Calendar API response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error adding event to calendar:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
    }
    throw error;
  }
};
