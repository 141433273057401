import React from 'react';

const ProceedingTypeCheckbox = ({ type, isChecked, onCheck }) => (
  <button
    className={`proceeding-type-button ${isChecked ? 'active' : ''}`}
    onClick={() => onCheck(type)}
    type="button"
  >
    {type}
  </button>
);

export default ProceedingTypeCheckbox;
