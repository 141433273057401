import React, { useState, useCallback } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useMsal } from '@azure/msal-react';
import { BrowserAuthError } from '@azure/msal-browser';
import { addEventToCalendar } from '../services/calendarService';

const CalendarIntegration = ({ title, date }) => {
  const [eventLink, setEventLink] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { instance } = useMsal();
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  const createOutlookEvent = useCallback(async (accessToken) => {
    console.log('Creating Outlook event:', { title, date });
    try {
      console.log('Access token:', accessToken);
      const res = await addEventToCalendar(title, date, 'outlook', accessToken);
      console.log('Outlook event creation response:', res);
      if (res.success) {
        setEventLink(res.result.webLink);
        console.log('Outlook event created successfully');
      } else {
        setErrorMessage(res.error || 'Failed to create event');
        console.error('Failed to create Outlook event:', res.error);
      }
    } catch (error) {
      console.error('Error creating Outlook calendar event:', error);
      setErrorMessage(error.message || 'An error occurred');
    }
  }, [title, date]);

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log('Google login successful, creating event:', { title, date });
      try {
        const res = await addEventToCalendar(title, date, 'google', tokenResponse.access_token);
        console.log('Google event creation response:', res);
        if (res.success) {
          setEventLink(res.result.htmlLink);
          console.log('Google event created successfully');
        } else {
          setErrorMessage(res.error || 'Failed to create event');
          console.error('Failed to create Google event:', res.error);
        }
      } catch (error) {
        console.error('Error creating Google calendar event:', error);
        setErrorMessage(error.response?.data?.error || error.message || 'An error occurred');
      }
    },
    onError: (error) => {
      console.error('Google login error:', error);
      setErrorMessage('Google authentication failed');
    },
    scope: 'https://www.googleapis.com/auth/calendar.events',
  });

  const outlookLogin = async () => {
    if (isAuthenticating) return;
    setIsAuthenticating(true);
    console.log('Initiating Outlook login');
    try {
      const authResult = await instance.loginPopup({
        scopes: ['Calendars.ReadWrite'],
        prompt: 'select_account',
      });
      console.log('Outlook login successful, auth result:', authResult);
      await createOutlookEvent(authResult.accessToken);
    } catch (error) {
      if (error instanceof BrowserAuthError && error.errorCode === 'user_cancelled') {
        console.log('User cancelled the Outlook login.');
      } else {
        console.error('Outlook login error:', error);
        setErrorMessage(`Outlook authentication failed: ${error.message || 'Unknown error'}`);
      }
    } finally {
      setIsAuthenticating(false);
    }
  };

  return (
    <div className="calendar-integration">
      <div className="calendar-buttons">
        <button type="button" className="google-calendar-button" onClick={googleLogin}>
          <img
            src={`${process.env.PUBLIC_URL}/Images/google-calendar-icon.svg`}
            alt="Add to Google Calendar"
            style={{ width: '28px', height: '28px' }}
          />
        </button>
        <button
          type="button"
          className="outlook-calendar-button"
          onClick={outlookLogin}
          disabled={isAuthenticating}
        >
          <img
            src={`${process.env.PUBLIC_URL}/Images/outlook-icon.svg`}
            alt="Add to Outlook Calendar"
            style={{ width: '28px', height: '28px' }}
          />
        </button>
      </div>
      {(eventLink || errorMessage) && (
        <div className="calendar-integration-message">
          {eventLink && (
            <p>
              Event created!
              {' '}
              <a href={eventLink} target="_blank" rel="noopener noreferrer">
                View event
              </a>
            </p>
          )}
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </div>
      )}
    </div>
  );
};

export default CalendarIntegration;
