import axios from 'axios';

const baseURL = process.env.NODE_ENV === 'production'
  ? 'https://auslawcalc.com.au/api'
  : 'http://localhost:3000/api';

const api = axios.create({ baseURL });

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      },
    };
  }
  return config;
});

export default api;
