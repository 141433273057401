import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import mixpanel from 'mixpanel-browser';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { store } from './store';
import AppContent from './AppContent';
import LoginPage from './components/LoginPage';
import './App.css';
import AuthVerify from './components/MagicLink';

// eslint-disable-next-line max-len
mixpanel.init('f337d6a5eee6d4897a1c9f226c54e089', {
  debug: true,
  track_pageview: true,
  persistence: 'localStorage',
});

const queryClient = new QueryClient();

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_OUTLOOK_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/common',
    clientSecret: process.env.REACT_APP_OUTLOOK_CLIENT_SECRET,
    redirectUri: 'https://auslawcalc.com.au/outlooksinglepage',
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

const createOutlookEvent = async (token) => {
  try {
    console.log('Creating event with token:', token);
    const response = await fetch('https://auslawcalc.com.au/api/calendar/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        title: 'Sample Event',
        date: new Date().toISOString(),
        token,
        calendarType: 'outlook'
      })
    });

    const result = await response.json();
    console.log('Event creation result:', result);

    if (result.success) {
      console.log('Event created successfully:', result);
    } else {
      console.error('Event creation failed:', result.error);
    }
  } catch (error) {
    console.error('Error creating event:', error);
  }
};

const isDevelopment = process.env.NODE_ENV === 'development';
const bypassAuth = isDevelopment && process.env.REACT_APP_BYPASS_AUTH === 'true';

function AppWrapper() {
  const navigate = useNavigate();

  useEffect(() => {
    const userEmail = localStorage.getItem('userEmail');

    console.log('Environment:', process.env.NODE_ENV);
    console.log('REACT_APP_BYPASS_AUTH:', process.env.REACT_APP_BYPASS_AUTH);
    console.log('isDevelopment:', isDevelopment);
    console.log('bypassAuth:', bypassAuth);
    console.log('userEmail:', userEmail);

    if (!userEmail && !bypassAuth) {
      console.log('Redirecting to login');
      navigate('/login');
    } else {
      console.log('User authenticated or auth bypassed');
    }
  }, [navigate]);

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/auth/verify" element={<AuthVerify />} />
      <Route path="/" element={<AppContent />} />
    </Routes>
  );
}

function App() {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const authStatus = params.get('auth');
    const accessToken = params.get('access_token');

    console.log('Auth status:', authStatus);
    console.log('Access token:', accessToken);

    if (authStatus === 'success' && accessToken) {
      createOutlookEvent(accessToken);
    } else if (authStatus === 'error') {
      console.error('Authentication failed');
    }
  }, []);

  return (
    <Router>
      <MsalProvider instance={msalInstance}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <QueryClientProvider client={queryClient}>
            <Provider store={store}>
              <AppWrapper />
            </Provider>
          </QueryClientProvider>
        </GoogleOAuthProvider>
      </MsalProvider>
    </Router>
  );
}

export default App;
