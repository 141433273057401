/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CriminalDateInput = ({ label, date, onDateChange }) => (
  <div className="criminal-date-input">
    <label htmlFor={label}>
      {label}
      :
    </label>
    <DatePicker
      id={label}
      selected={date ? new Date(date) : null}
      onChange={(newDate) => onDateChange(newDate)}
      dateFormat="dd/MM/yyyy"
    />
  </div>
);

export default CriminalDateInput;
