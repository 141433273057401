import React from 'react';
import { format, addBusinessDays, subBusinessDays, isValid } from 'date-fns';

const DateSelector = ({ chosenDate, onDateChange }) => {
  const handleIncrement = () => {
    const date = chosenDate instanceof Date ? chosenDate : new Date(chosenDate);
    if (isValid(date)) {
      onDateChange(addBusinessDays(date, 1));
    }
  };

  const handleDecrement = () => {
    const date = chosenDate instanceof Date ? chosenDate : new Date(chosenDate);
    if (isValid(date)) {
      onDateChange(subBusinessDays(date, 1));
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowLeft') {
      handleDecrement();
    } else if (e.key === 'ArrowRight') {
      handleIncrement();
    }
  };

  const formattedDate = isValid(new Date(chosenDate))
    ? format(new Date(chosenDate), 'EEE, dd/MM/yyyy')
    : 'Invalid Date';

  return (
    <div
      className="date-selector-container"
      onKeyDown={handleKeyDown}
      tabIndex={0}
      role="group"
      aria-label="Date selector"
    >
      <button type="button" className="date-selector-button" onClick={handleDecrement} aria-label="Previous day">
        &lt;
      </button>
      <div className="chosen-date">
        <span>{formattedDate}</span>
      </div>
      <button type="button" className="date-selector-button" onClick={handleIncrement} aria-label="Next day">
        &gt;
      </button>
    </div>
  );
};

export default DateSelector;
