/* eslint-disable max-len */
// components/ExportOverlay.jsx
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const ExportOverlay = ({ onClose, onExport }) => {
  const [formData, setFormData] = useState({
    NO: '',
    PLAINTIFF: '',
    DEFENDANT: '',
    DOC_DATE: null,
    PREPARED_BY: '',
    SOLICITORS_CODE: '',
    EMAIL_ADDRESS: '',
    REF_NUMBER: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleExport = () => {
    onExport(formData);
    onClose();
  };

  const handleOverlayClick = (e) => {
    e.stopPropagation(); // Prevent click from propagating to parent
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div
      className="overlay"
      onClick={handleOverlayClick}
      onKeyDown={handleKeyDown}
      role="dialog"
      aria-modal="true"
      tabIndex={0}
    >
      <div className="overlay-content">
        <h2 className="overlay-title">Download Pre-Filled Document</h2>
        <p className="overlay-subheading">All fields are optional</p>
        <div className="input-group">
          <label htmlFor="no">Court Case ID</label>
          <input type="text" id="no" name="NO" value={formData.NO} onChange={handleChange} />
        </div>
        <div className="input-group">
          <label htmlFor="plaintiff">Plaintiff</label>
          <input type="text" id="plaintiff" name="PLAINTIFF" value={formData.PLAINTIFF} onChange={handleChange} />
        </div>
        <div className="input-group">
          <label htmlFor="defendant">Defendant</label>
          <input type="text" id="defendant" name="DEFENDANT" value={formData.DEFENDANT} onChange={handleChange} />
        </div>
        <div className="input-group">
          <label htmlFor="doc_date">Date of Document</label>
          <DatePicker
            id="doc_date"
            selected={formData.DOC_DATE}
            onChange={(date) => setFormData({ ...formData, DOC_DATE: date })}
            dateFormat="dd/MM/yyyy"
            placeholderText="Select a date"
          />
        </div>

        <div className="input-group">
          <label htmlFor="prepared_by">Prepared by</label>
          <input type="text" id="prepared_by" name="PREPARED_BY" value={formData.PREPARED_BY} onChange={handleChange} />
        </div>
        <div className="input-group">
          <label htmlFor="solicitors_code">Solicitors Code</label>
          <input type="text" id="solicitors_code" name="SOLICITORS_CODE" value={formData.SOLICITORS_CODE} onChange={handleChange} />
        </div>
        <div className="input-group">
          <label htmlFor="email_address">Email</label>
          <input type="text" id="email_address" name="EMAIL_ADDRESS" value={formData.EMAIL_ADDRESS} onChange={handleChange} />
        </div>
        <div className="input-group">
          <label htmlFor="ref_number">Matter Reference Number</label>
          <input type="text" id="ref_number" name="REF_NUMBER" value={formData.REF_NUMBER} onChange={handleChange} />
        </div>
        <button type="button" className="export-btn" onClick={handleExport}>
          Export Document
        </button>
      </div>
    </div>
  );
};

export default ExportOverlay;
