import React, { useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { sendMagicLink } from '../services/apiService';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await sendMagicLink(email);
      setMessage(response.message);
      if (response.message === 'Magic link sent successfully') {
        mixpanel.track('Magic Link Sent', { email });
      }
    } catch (error) {
      console.error('Error:', error.response?.data || error.message);
      setMessage('Error sending magic link. Please try again.');
    }
  };

  return (
    <div className="login-page">
      <h1>AusLawCalc Login</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          required
        />
        <button type="submit">Send Magic Link</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
}

export default LoginPage;
