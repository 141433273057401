import React from 'react';
import { useQuery } from 'react-query';
import { fetchDownloadableLink } from '../services/apiService';

const DownloadableLink = ({ stateId, courtId, isCriminalDivision }) => {
  const { data: link, isLoading, error } = useQuery(
    ['downloadableLink', stateId, courtId, isCriminalDivision],
    () => fetchDownloadableLink(stateId, courtId, isCriminalDivision),
    {
      enabled: !!stateId && !!courtId,
    }
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <div>
        Error:
        {error.message}
      </div>
    );
  }

  if (!link) {
    return null;
  }

  return (
    <div className="downloadable-link">
      <span>Practice Notes Download Link: </span>
      <div className="practisenotes-download">
        <a href={link.url} target="_blank" rel="noopener noreferrer">
          {link.title}
        </a>
      </div>
    </div>
  );
};

export default DownloadableLink;
