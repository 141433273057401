import { createSlice } from '@reduxjs/toolkit';
import { fetchCalculatedDates } from '../services/apiService'; // Adjust the path as needed

const requirementsSlice = createSlice({
  name: 'requirements',
  initialState: { topDates: {}, requirements: [] },
  reducers: {
    setRequirements: (state, action) => {
      if (Array.isArray(action.payload)) {
        // If it's just an array of requirements
        return { ...state, requirements: action.payload };
      }
      // If it's an object with topDates and requirements
      return { ...state, ...action.payload };
    },
    updateChosenDate: (state, action) => {
      const { description, chosenDate } = action.payload;
      const dateString = chosenDate instanceof Date ? chosenDate.toISOString() : chosenDate;
      if (state.topDates[description]) {
        state.topDates[description] = dateString;
      } else {
        const requirement = state.requirements.find((req) => req.description === description);
        if (requirement) {
          requirement.chosenDate = dateString;
        }
      }
    },
  },
});

export const { setRequirements, updateChosenDate } = requirementsSlice.actions;
export default requirementsSlice.reducer;

export const fetchRequirements = (matterId, dateOfOrder) => async (dispatch) => {
  try {
    console.log('Fetching requirements action triggered:', { matterId, dateOfOrder });
    const { calculatedDates, requirements } = await fetchCalculatedDates(matterId, dateOfOrder);
    console.log('Received requirements:', requirements);
    console.log('Received calculated dates:', calculatedDates);

    // Format dates before dispatching
    const formattedRequirements = requirements.map((req) => ({
      ...req,
      calculatedDate: req.calculatedDate ? new Date(req.calculatedDate).toISOString() : null,
      chosenDate: req.chosenDate ? new Date(req.chosenDate).toISOString() : null
    }));

    const formattedTopDates = Object.entries(calculatedDates).reduce((acc, [key, value]) => ({
      ...acc,
      [key]: value ? new Date(value).toISOString() : null
    }), {});

    dispatch(setRequirements({
      topDates: formattedTopDates,
      requirements: formattedRequirements
    }));
  } catch (error) {
    console.error('Error fetching requirements:', error);
    // Handle error (e.g., dispatch an error action)
  }
};
