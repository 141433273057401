import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { fetchCalculatedDates } from '../services/apiService';

export const useMatterCalculations = () => {
  const { matterId, dateOfOrder } = useSelector((state) => state.matter);

  return useQuery(
    ['calculatedDates', matterId, dateOfOrder],
    () => fetchCalculatedDates(matterId, dateOfOrder),
    {
      enabled: !!matterId && !!dateOfOrder,
    }
  );
};
