import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DateSelector from './DateSelector';
import { updateChosenDate } from '../store/requirementsSlice';
import CalendarIntegration from './CalendarIntegration';
// import InfoTooltip from './InfoTooltip';

const RequirementList = () => {
  const dispatch = useDispatch();
  const { topDates, requirements } = useSelector((state) => state.requirements);

  const handleDateChange = (description, newDate) => {
    if (newDate) {
      dispatch(updateChosenDate({ description, chosenDate: newDate }));
    }
  };

  // eslint-disable-next-line no-unused-vars
  const renderDateItem = (description, date, requirement) => (
    <div key={description} className="requirement-item">
      <div className="requirement-description">
        {description}
        {/* {requirement && <InfoTooltip requirement={requirement} />} */}
      </div>
      <div className="requirement-date-and-calendar-wrapper">
        <div className="date-selector-wrapper">
          <DateSelector
            chosenDate={date ? new Date(date) : null}
            onDateChange={(newDate) => handleDateChange(description, newDate)}
          />
        </div>
        <div className="calendar-integration-wrapper">
          <CalendarIntegration
            title={description}
            date={date ? new Date(date) : null}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="requirement-list">
      {Object.entries(topDates).map(([description, date]) => {
        let offsetValue;
        if (description === 'Trial Date') {
          offsetValue = 52;
        } else if (description === 'Mediation Date') {
          offsetValue = 46;
        } else {
          offsetValue = 40;
        }

        return renderDateItem(description, date, {
          description,
          fromDateType: 'Date of Order',
          offsetValue,
          offsetUnit: 'weeks',
          direction: 'after'
        });
      })}
      {requirements.map((req) => renderDateItem(req.description, req.chosenDate || req.calculatedDate, req))}
    </div>
  );
};

export default RequirementList;
