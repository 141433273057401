import React from 'react';
import DateSelector from './DateSelector';
import CalendarIntegration from './CalendarIntegration';

const CriminalRequirementList = ({ calculatedDates, onDateChange, selectedProceedingType, requirements }) => {
  const uniqueRequirements = requirements.reduce((acc, current) => {
    const x = acc.find((item) => item.description === current.description);
    if (!x) {
      return acc.concat([current]);
    }
    return acc;
  }, []);

  console.log('CriminalRequirementList props:', { calculatedDates, selectedProceedingType, requirements });

  return (
    <div className="requirement-list">
      {uniqueRequirements.map((requirement) => {
        const date = calculatedDates[requirement.description];
        return (
          <div key={requirement.id} className="requirement-item">
            <div className="requirement-description">
              {requirement.description}
            </div>
            <div className="requirement-date-and-calendar-wrapper">
              <DateSelector
                chosenDate={date ? new Date(date) : null}
                onDateChange={(newDate) => onDateChange(requirement.description, newDate)}
              />
              <CalendarIntegration
                title={requirement.description}
                date={date}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CriminalRequirementList;
