import React, { useState, useEffect } from 'react';
import { fetchStates } from '../services/apiService';

function StateSelection({ onSelectState, disabled }) {
  const [states, setStates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedState, setSelectedState] = useState('');

  useEffect(() => {
    const getStates = async () => {
      setLoading(true);
      try {
        const data = await fetchStates();
        setStates(data);
        setError(null);
      } catch (err) {
        setError('Failed to fetch states');
      } finally {
        setLoading(false);
      }
    };
    getStates();
  }, []);

  const handleChange = (e) => {
    const { value } = e.target;
    setSelectedState(value);
    if (typeof onSelectState === 'function') {
      onSelectState(value);
    }
  };

  return (
    <div>
      <label htmlFor="state-select" className="state-select-label">
        Select State:
      </label>
      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      <select
        id="state-select"
        className="select-input state-select"
        onChange={handleChange}
        value={selectedState}
        disabled={disabled}
      >
        <option value="">Select a state</option>
        {states.map((state) => (
          <option key={state.id} value={state.id}>
            {state.name}
          </option>
        ))}
      </select>
    </div>
  );
}

export default StateSelection;
